import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import './global.css';
import { AuthProvider } from './context/auth-context';

render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root')
);
