import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth } from '../context/auth-context';

const loadAuthenticated = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticated);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const CircularScreenProgress = withStyles({
  root: {
    position: 'absolute',
    left: '50%',
    top: '50%'
  }
})(CircularProgress);

function App() {
  const {
    data: { access_token }
  } = useAuth();

  React.useEffect(() => {
    // pre-load the authenticated side in the background while the user's
    // filling out the login form.
    loadAuthenticated();
  }, []);
  return (
    <React.Suspense fallback={<CircularScreenProgress />}>
      {access_token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}

export default App;
