import { getUser } from '../clients/auth-client';

async function bootstrapAppData() {
  const data = await getUser();
  if (!data) {
    return { access_token: null };
  }
  const { access_token } = data;
  return {
    access_token
  };
}

export { bootstrapAppData };
